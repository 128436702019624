(() => {
  let app = {
    init: () => {
      svg4everybody();

      app._accordion(true); // --- ACCORDION

      if (document.querySelector('#menu-toggler')) {
        const menuToggler = document.querySelector('#menu-toggler');
        const menu = document.querySelector('#menu');

        menuToggler.onclick = e => {
          e.preventDefault();
          const _this = e.currentTarget;

          if (_this.classList.contains('is-active') || menu.classList.contains('is-active')) {
            _this.classList.remove('is-active');
            menu.classList.remove('is-active');
            return;
          }

          _this.classList.add('is-active');
          menu.classList.add('is-active');
        };
      }

      if (document.querySelector('.js-baguettebox')) {
        baguetteBox.run('.js-baguettebox', {
          overlayBackgroundColor: 'rgba(255, 255, 255, 0.9)'
        });

        const galleryItems = document.querySelectorAll('.js-fj-gallery');

        fjGallery(document.querySelectorAll('.js-fj-gallery'), {
          itemSelector: '.js-fj-gallery-item',
          gutter: {
            horizontal: 30,
            vertical: 30
          },
          rowHeightTolerance: 0.5
        });
      }

      app._createMap('map');

      if (document.querySelector('[data-bouncer-validate]')) {
        // --- VALIDATION
        var bouncer = new Bouncer('[data-bouncer-validate]', {
          messageAfterField: true,
          disableSubmit: true,
          messages: {
            missingValue: {
              default: 'Заполните поле',
              checkbox: 'Обязательное поле'
            },
            patternMismatch: {
              email: 'Не верный формат e-mail'
            }
          }
        });
      }
    },

    _accordion: (close = false) => {
      if (document.querySelector('.js-accordion')) {
        const acc = document.querySelectorAll('.js-accordion');

        [].forEach.call(acc, item => {
          const button = item.querySelector('.js-accordion-button');

          button.addEventListener('click', e => {
            let active = false;

            if (item.classList.contains('is-active')) {
              active = true;
            }

            if (active) {
              item.classList.remove('is-active');

              if (close) {
                [].forEach.call(acc, itemAll => {
                  itemAll.classList.remove('is-active');
                });
              }

              return false;
            }

            if (!active) {
              if (close) {
                [].forEach.call(acc, itemAll => {
                  itemAll.classList.remove('is-active');
                });
              }

              item.classList.add('is-active');
            }
          });
        });
      }
    },

    _createSlider: (elemID, opts) => {
      if (elemID === undefined) return;

      if (document.querySelector(elemID)) {
        const slider = new Swiper(elemID, opts);

        window.onresize = () => {
          slider.update();
        };

        return slider;
      }
    },

    _createMap: elemID => {
      if (elemID === undefined) return;

      if (document.getElementById(elemID)) {
        const elem = document.getElementById(elemID);

        const center = JSON.parse(elem.getAttribute('data-center'));
        const mark = JSON.parse(elem.getAttribute('data-mark'));
        const markText = elem.getAttribute('data-mark-text');

        ymaps.ready(function() {
          const map = new ymaps.Map(elemID, {
            center,
            zoom: 16,
            controls: ['zoomControl', 'typeSelector', 'fullscreenControl']
          });

          map.behaviors.disable('scrollZoom');

          const placemark = new ymaps.Placemark(
            mark,
            {
              iconContent: markText
            },
            {
              preset: 'islands#darkBlueStretchyIcon'
            }
          );

          map.geoObjects.add(placemark);
        });
      }
    },

    _getWinSize: () => {
      const w =
        window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      const h =
        window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      return { w, h };
    }
  };

  app.init();
})();
